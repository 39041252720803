@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "NeurialGrotesk";
  src: url("./fonts/NeurialGrotesk-Regular.woff2") format("woff2"),
    url("./fonts/NeurialGrotesk-Regular.woff") format("woff"),
    url("./fonts/NeurialGrotesk-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NeurialGrotesk";
  src: url("./fonts/NeurialGrotesk-Medium.woff2") format("woff2"),
    url("./fonts/NeurialGrotesk-Medium.woff") format("woff"),
    url("./fonts/NeurialGrotesk-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

html {
  background-color: white;
}
